import React, { useRef } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import useStore from '../store/useStore'
import ContentHeader from '../components/Typography/ContentHeader'
import ContentText from '../components/Typography/ContentText'
import InnerLink from '../components/Typography/InnerLink'
import MediumImage from '../components/Image/MediumImage'
// import SectionStyle1 from '../components/curves/SectionStyle1'
import SEO from '../layouts/Seo'
import ImgWrapper from '../components/Image/ImgWrapper'
import ImgLightBox from '../components/lightbox/ImgLightBox'
import GeneralCarousel from '../contents/carousel/GeneralCarousel'
import HasirTelKullanim from '../contents/common/HasirtelKullanim'
const FensTelPage = () => {
  const { getImageByName } = useStore()
  const lightBoxRef = useRef()
  return (
    <>
      <SEO
        title='Fens Teli İmalatı - Erez Kaynak'
        description='Firmamız bahçe çevre güvenliğinde, basketbol sahalarında, tenis kortları çevresinde ve arazi çevresinde koruma amaçlı punta kaynaklı fens teli imalatı yapmaktadır. Firmamız sıcak daldırma galvaniz , elektro galvaniz yada siyah ham çelik tel malzeme kullanarak punta kaynaklı fens teli imalatı yapmaktadır.'
        keywords='fens,Fens Teli İmalatı,fens teli,paslanmaz fens,paslanmaz fens teli,galvaniz fens,galvaniz fens teli,tel fens,fens tel,fen teli fiyatları,fens fiyatları,fens teli istanbul,boyalı fens teli,yeşil fens teli'
        url='fens-teli-imalati'
        imgurl={getImageByName('celikhasirtel4').sm.src}
      />
      <ImgLightBox ref={lightBoxRef} gallery={[getImageByName('celikhasirtel4').original.src]} />
      <Box paddingX={3} paddingY={5}>
        <Grid container spacing={6} alignItems='flex-start'>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
            <ContentHeader title='Fens Teli İmalatı' description='Türkiyenin her yerine gönderim yapılmaktadır.' />
            <ContentText>
              <p>
                Firmamız bahçe çevre güvenliğinde, basketbol sahalarında, tenis kortları çevresinde ve arazi çevresinde koruma amaçlı{' '}
                <strong>punta kaynaklı fens teli</strong> imalatı yapmaktadır.
              </p>
              <p>
                Firmamız <strong>sıcak daldırma galvaniz, elektro galvaniz ya da siyah ham çelik tel</strong> malzeme kullanarak
                <strong>punta kaynaklı fens teli</strong> imalatı yapmaktadır.
              </p>
              <p>
                Firmamız fens teli boyasız olarak imalatını yapmaktadır. Daha sonrasında pvc kaplanabilir ya da fırın boya ile boyanabilir.
              </p>
              <p>
                Tel kalınlığı 2mm den 8mm tel kalınlığına kadar <strong>fens teli</strong> imalatını yapabiliyoruz.
              </p>
              Göz aralığı dikdörtgen de olmak üzere istenilen her ölçüde <strong>fens teli</strong> yapılmaktadır.
              <p>
                Özel göz aralıklarında (örn; 35x75mm vb.) yeni kalıp yaparak hızlı bir şekilde müşterilerimizin ihtiyacı olan{' '}
                <strong>fens telini</strong> uygun bir maliyet ile üretiyoruz.
              </p>
              <p>
                Fens tellere Elektrostatik toz fırın boya işlemi yapılabilmektedir. Standart ya da özel renk seçenekleri ile hasır teller
                elektrostatik toz boya ile boyanmaktadır. Firmamız bünyesinde boyasız olarak hasır tel imalatı gerçekleştirip, çalışmış
                olduğumuz elektrostatik toz boya yapan firmalar ile ihtiyacınız karşılanmaktadır.
              </p>
              <p>Detaylı bilgi yada fiyat teklifi almak için bizi arayınız.</p>
            </ContentText>
            <Box textAlign='center'>
              <InnerLink title='İletişim Bilgilerimiz İçin Tıklayınız' url='/iletisim' />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
            <ImgWrapper onClick={() => lightBoxRef.current.openLightBox()}>
              <MediumImage lazyHeight={500} img={getImageByName('celikhasirtel4')} alt='Özel hasır tel imalatı' />
            </ImgWrapper>
            <Box mt={2}>
              <MediumImage lazyHeight={500} img={getImageByName('celikhasirolculeri')} alt='özel göz hasır tel imalatı erez kaynak' />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <GeneralCarousel />

      <HasirTelKullanim title='Fens Teli Kullanım Alanları' transparentBG />
    </>
  )
}

export default FensTelPage
